@import '~@dcos/ui-kit/dist/packages/design-tokens/build/less/_designTokens';

& when (@login-modal-enabled) {

  .modal-backdrop.login-modal-backdrop {
    background:
      radial-gradient(circle at 0% 90%, rgba(125, 88, 255, 0.5), transparent 50%),
      radial-gradient(circle at 100% 10%, rgba(255, 0, 125, 0.3) 0%, transparent 60%),
      #251a4c;
  }

  .login-logo {
    display: block;
    height: @iconSize-l;
    left: 50%;
    position: absolute;
    top: @login-modal-logo-name-vertical-offset;
    transform: translate(-50%, -100%);
  }

  .modal {

    &.login-modal {
      margin-top: -0.5 * @login-modal-logo-name-vertical-offset;
      overflow: visible;

      .modal-header,
      .modal-footer {
        border: none;
      }

      .modal-header {
        padding: 0;
      }

      .modal-body {
        height: auto;
        position: relative;
      }

      .modal-footer {
        padding-top: 0;
        position: relative;
      }

      .login-modal-product-name {
        left: 50%;
        position: absolute;
        top: @space-m;
        transform: translate(-50%, 100%);
        width: 100%;
      }

      .login-modal-product-name a {
        color: inherit;
      }
    }
  }
}
