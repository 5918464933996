& when (@menu-tabbed-enabled) {

  .menu-tabbed-container-vertical {
    display: flex;
    flex: 1 1 auto;

    .menu-tabbed-view-container {
      flex: 1 1 auto;
      min-width: 1px;
    }
  }

  .menu-tabbed-container-fixed {
    // force the menu to it's own composite layer to fix a bug in
    // Firefox 63 and below on Windows and Linux
    transform: translateZ(0);

    .menu-tabbed-vertical {
      height: 100%;
      position: fixed;
    }
  }

  .menu-tabbed {
    margin-bottom: @menu-tabbed-margin-bottom;
    margin-top: @menu-tabbed-margin-top;
    position: relative;

    &.short {
      margin-bottom: @menu-tabbed-margin-bottom * @menu-tabbed-short-margin-bottom-scale;
      margin-top: @menu-tabbed-margin-top * @menu-tabbed-short-margin-top-scale;
    }

    &.tall {
      margin-bottom: @menu-tabbed-margin-bottom * @menu-tabbed-tall-margin-bottom-scale;
      margin-top: @menu-tabbed-margin-top * @menu-tabbed-tall-margin-top-scale;
    }
  }

  .menu-tabbed-vertical {
    flex: 0 0 auto;
    margin-bottom: @menu-tabbed-vertical-margin-bottom;
    margin-right: @menu-tabbed-vertical-margin-right;
    position: relative;
    width: @menu-tabbed-vertical-width;

    &:after {
      background: @menu-tabbed-border;
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      width: 1px;
      z-index: @z-index-header-border;
    }

    .menu-tabbed-item {
      display: block;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }

      .menu-tabbed-item {
        margin-left: @menu-tabbed-vertical-item-margin-left;

        .menu-tabbed-item-label {
          margin-left: @menu-tabbed-vertical-item-margin-left * -1;
          padding-left: @menu-tabbed-vertical-item-margin-left;
        }
      }
    }

    .menu-tabbed-item-label {
      max-width: none;
      overflow: hidden;
      padding-bottom: @menu-tabbed-vertical-item-link-padding-bottom;
      padding-top: @menu-tabbed-vertical-item-link-padding-top;
      text-overflow: ellipsis;

      &:after {
        bottom: 0;
        height: 100%;
        left: auto;
        right: 0;
        width: 2px;
      }
    }
  }

  .menu-tabbed-item {
    display: inline-block;
    margin-bottom: 0;
    margin-right: @menu-tabbed-horizontal-item-margin-right;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      display: none;
    }

    &.selected,
    &.active {

      & > .menu-tabbed-item-label {
        color: @menu-tabbed-item-link-selected-color;

        &:after {
          background: @menu-tabbed-item-link-selected-color;
          display: block;
        }
      }
    }
  }

  .menu-tabbed-item-label {
    color: @menu-tabbed-item-link-color;
    cursor: pointer;
    display: block;
    font-weight: @menu-tabbed-item-link-font-weight;
    padding-bottom: @menu-tabbed-horizontal-item-link-padding-bottom;
    position: relative;
    text-decoration: @menu-tabbed-item-link-text-decoration;

    &:hover {
      color: @menu-tabbed-item-link-hover-color;
      text-decoration: @menu-tabbed-item-link-text-decoration;

      &:after {
        background: @menu-tabbed-item-link-hover-color;
      }
    }

    &:active {
      color: @menu-tabbed-item-link-active-color;

      &:after {
        background: @menu-tabbed-item-link-active-color;
      }
    }

    &:after {
      background-color: @menu-tabbed-item-link-color;
      bottom: 0;
      content: '';
      display: none;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: @z-index-menu-tabbed-item-active-indicator;
    }

    &.selected,
    &.active {
      color: @menu-tabbed-item-link-selected-color;

      &:after {
        background: @menu-tabbed-item-link-selected-color;
        display: block;
      }
    }

    &.menu-tabbed-item-label-with-badge {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
    }

    &.padded-right {
      padding-right: 5px;
    }
  }

  .form-tabs-list {
    max-width: @menu-tabbed-vertical-width;
  }
}

& when (@menu-tabbed-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .menu-tabbed {
      margin-bottom: @menu-tabbed-margin-bottom-screen-small;

      &.short {
        margin-bottom: @menu-tabbed-margin-bottom-screen-small * @menu-tabbed-short-margin-bottom-scale;
      }

      &.tall {
        margin-bottom: @menu-tabbed-margin-bottom-screen-small * @menu-tabbed-tall-margin-bottom-scale;
      }
    }

    .menu-tabbed-vertical {
      margin-bottom: @menu-tabbed-vertical-margin-bottom-screen-small;
      margin-right: @menu-tabbed-vertical-margin-right-screen-small;
      width: @menu-tabbed-vertical-width-screen-small;

      .menu-tabbed-item {

        .menu-tabbed-item {
          margin-left: @menu-tabbed-vertical-item-margin-left-screen-small;

          .menu-tabbed-item-label {
            margin-left: @menu-tabbed-vertical-item-margin-left-screen-small * -1;
            padding-left: @menu-tabbed-vertical-item-margin-left-screen-small;
          }
        }
      }

      .menu-tabbed-item-label {
        padding-bottom: @menu-tabbed-vertical-item-link-padding-bottom-screen-small;
        padding-top: @menu-tabbed-vertical-item-link-padding-top-screen-small;
      }
    }

    .menu-tabbed-item {
      margin-right: @menu-tabbed-horizontal-item-margin-right-screen-small;
    }

    .menu-tabbed-item-label {
      padding-bottom: @menu-tabbed-horizontal-item-link-padding-bottom-screen-small;
    }

    .form-tabs-list {
      max-width: @menu-tabbed-vertical-width-screen-small;
    }
  }
}

& when (@menu-tabbed-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .menu-tabbed {
      margin-bottom: @menu-tabbed-margin-bottom-screen-medium;

      &.short {
        margin-bottom: @menu-tabbed-margin-bottom-screen-medium * @menu-tabbed-short-margin-bottom-scale;
      }

      &.tall {
        margin-bottom: @menu-tabbed-margin-bottom-screen-medium * @menu-tabbed-tall-margin-bottom-scale;
      }
    }

    .menu-tabbed-vertical {
      margin-bottom: @menu-tabbed-vertical-margin-bottom-screen-medium;
      margin-right: @menu-tabbed-vertical-margin-right-screen-medium;
      width: @menu-tabbed-vertical-width-screen-medium;

      .menu-tabbed-item {

        .menu-tabbed-item {
          margin-left: @menu-tabbed-vertical-item-margin-left-screen-medium;

          .menu-tabbed-item-label {
            margin-left: @menu-tabbed-vertical-item-margin-left-screen-medium * -1;
            padding-left: @menu-tabbed-vertical-item-margin-left-screen-medium;
          }
        }
      }

      .menu-tabbed-item-label {
        padding-bottom: @menu-tabbed-vertical-item-link-padding-bottom-screen-medium;
        padding-top: @menu-tabbed-vertical-item-link-padding-top-screen-medium;
      }
    }

    .menu-tabbed-container-fixed {

      .menu-tabbed-view-container {
        padding-left: @menu-tabbed-vertical-width-screen-medium + @menu-tabbed-vertical-margin-right-screen-medium;
      }
    }

    .menu-tabbed-item {
      margin-right: @menu-tabbed-horizontal-item-margin-right-screen-medium;
    }

    .menu-tabbed-item-label {
      padding-bottom: @menu-tabbed-horizontal-item-link-padding-bottom-screen-medium;
    }

    .form-tabs-list {
      max-width: @menu-tabbed-vertical-width-screen-medium;
    }
  }
}

& when (@menu-tabbed-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .menu-tabbed {
      margin-bottom: @menu-tabbed-margin-bottom-screen-large;

      &.short {
        margin-bottom: @menu-tabbed-margin-bottom-screen-large * @menu-tabbed-short-margin-bottom-scale;
      }

      &.tall {
        margin-bottom: @menu-tabbed-margin-bottom-screen-large * @menu-tabbed-tall-margin-bottom-scale;
      }
    }

    .menu-tabbed-vertical {
      margin-bottom: @menu-tabbed-vertical-margin-bottom-screen-large;
      margin-right: @menu-tabbed-vertical-margin-right-screen-large;
      width: @menu-tabbed-vertical-width-screen-large;

      .menu-tabbed-item {

        .menu-tabbed-item {
          margin-left: @menu-tabbed-vertical-item-margin-left-screen-large;

          .menu-tabbed-item-label {
            margin-left: @menu-tabbed-vertical-item-margin-left-screen-large * -1;
            padding-left: @menu-tabbed-vertical-item-margin-left-screen-large;
          }
        }
      }

      .menu-tabbed-item-label {
        padding-bottom: @menu-tabbed-vertical-item-link-padding-bottom-screen-large;
        padding-top: @menu-tabbed-vertical-item-link-padding-top-screen-large;
      }
    }

    .menu-tabbed-container-fixed {

      .menu-tabbed-view-container {
        padding-left: @menu-tabbed-vertical-width-screen-large + @menu-tabbed-vertical-margin-right-screen-large;
      }
    }

    .menu-tabbed-item {
      margin-right: @menu-tabbed-horizontal-item-margin-right-screen-large;
    }

    .menu-tabbed-item-label {
      padding-bottom: @menu-tabbed-horizontal-item-link-padding-bottom-screen-large;
    }

    .form-tabs-list {
      max-width: @menu-tabbed-vertical-width-screen-large;
    }
  }
}

& when (@menu-tabbed-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .menu-tabbed {
      margin-bottom: @menu-tabbed-margin-bottom-screen-jumbo;

      &.short {
        margin-bottom: @menu-tabbed-margin-bottom-screen-jumbo * @menu-tabbed-short-margin-bottom-scale;
      }

      &.tall {
        margin-bottom: @menu-tabbed-margin-bottom-screen-jumbo * @menu-tabbed-tall-margin-bottom-scale;
      }
    }

    .menu-tabbed-vertical {
      margin-bottom: @menu-tabbed-vertical-margin-bottom-screen-jumbo;
      margin-right: @menu-tabbed-vertical-margin-right-screen-jumbo;
      width: @menu-tabbed-vertical-width-screen-jumbo;

      .menu-tabbed-item {

        .menu-tabbed-item {
          margin-left: @menu-tabbed-vertical-item-margin-left-screen-jumbo;

          .menu-tabbed-item-label {
            margin-left: @menu-tabbed-vertical-item-margin-left-screen-jumbo * -1;
            padding-left: @menu-tabbed-vertical-item-margin-left-screen-jumbo;
          }
        }
      }

      .menu-tabbed-item-label {
        padding-bottom: @menu-tabbed-vertical-item-link-padding-bottom-screen-jumbo;
        padding-top: @menu-tabbed-vertical-item-link-padding-top-screen-jumbo;
      }
    }

    .menu-tabbed-container-fixed {

      .menu-tabbed-view-container {
        padding-left: @menu-tabbed-vertical-width-screen-jumbo + @menu-tabbed-vertical-margin-right-screen-jumbo;
      }
    }

    .menu-tabbed-item {
      margin-right: @menu-tabbed-horizontal-item-margin-right-screen-jumbo;
    }

    .menu-tabbed-item-label {
      padding-bottom: @menu-tabbed-horizontal-item-link-padding-bottom-screen-jumbo;
    }

    .form-tabs-list {
      max-width: @menu-tabbed-vertical-width-screen-jumbo;
    }
  }
}
