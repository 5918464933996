& when (@modal-enabled) {

  // diplay ui-kit popups and tooltips in front of .modal-wrapper
  [data-popper-placement] {
    z-index: 1010 !important;
  }

  // using flexbox for centering fixes the blurry subpixel
  // rendering of dialog modals on low resolution screens
  .modal-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
  }

  // part of the flexbox centering fix
  .modal-small {
    position: unset !important;
    transform: none !important;
  }

  .modal {
    // We need the extra specificity here, so we have to nest.
    .modal-header,
    .modal-footer {
      flex: 0 0 auto;
    }
  }

  .modal-body-wrapper {
    flex: 1 1 auto;
  }

  // Remove margins from modal titles
  .modal-header-title {
    margin-bottom: 0;
    margin-top: 0;
  }

  // Remove border from footer
  .modal .modal-footer {
    border-top: none;
  }

  .modal-body {

    & > p {

      // Remove bottom margins on the last paragraph tag when they are a direct
      // descendent of .modal-body.
      &:last-child {
        margin-bottom: 0;
      }
    }

    & > div {

      &:last-child {

        & > p {

          // In some cases we wrap paragraph tags in divs, so we select the
          // last descending div, and then its last descending paragraph tag.
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .modal-close {
    appearance: none;
    background: transparent;
    border: 0;
    height: @base-spacing-unit;
    position: absolute;
    right: @base-spacing-unit * -1.5;
    top: @base-spacing-unit * -1.5;
    width: @base-spacing-unit;

    &:hover,
    &:active {
      background: transparent;

      .icon {
        color: @white;
      }
    }

    .icon {
      color: fade(@white, 80%);
    }
  }

  .modal-content-loading-indicator {
    height: 165px;
  }

  .modal-body-offset {
    margin: -@modal-body-padding-top -@modal-body-padding-right -@modal-body-padding-bottom -@modal-body-padding-left;
    overflow-x: hidden;
  }

  .modal-body-padding-surrogate {
    padding: @modal-body-padding-top @modal-body-padding-right @modal-body-padding-bottom @modal-body-padding-left;
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .modal-body-offset {
      margin: -@modal-body-padding-top-screen-small -@modal-body-padding-right-screen-small -@modal-body-padding-bottom-screen-small -@modal-body-padding-left-screen-small;
    }

    .modal-body-padding-surrogate {
      padding: @modal-body-padding-top-screen-small @modal-body-padding-right-screen-small @modal-body-padding-bottom-screen-small @modal-body-padding-left-screen-small;
    }
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .modal-body-offset {
      margin: -@modal-body-padding-top-screen-medium -@modal-body-padding-right-screen-medium -@modal-body-padding-bottom-screen-medium -@modal-body-padding-left-screen-medium;
    }

    .modal-body-padding-surrogate {
      padding: @modal-body-padding-top-screen-medium @modal-body-padding-right-screen-medium @modal-body-padding-bottom-screen-medium @modal-body-padding-left-screen-medium;
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .modal-body-offset {
      margin: -@modal-body-padding-top-screen-large -@modal-body-padding-right-screen-large -@modal-body-padding-bottom-screen-large -@modal-body-padding-left-screen-large;
    }

    .modal-body-padding-surrogate {
      padding: @modal-body-padding-top-screen-large @modal-body-padding-right-screen-large @modal-body-padding-bottom-screen-large @modal-body-padding-left-screen-large;
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .modal-body-offset {
      margin: -@modal-body-padding-top-screen-jumbo -@modal-body-padding-right-screen-jumbo -@modal-body-padding-bottom-screen-jumbo -@modal-body-padding-left-screen-jumbo;
    }

    .modal-body-padding-surrogate {
      padding: @modal-body-padding-top-screen-jumbo @modal-body-padding-right-screen-jumbo @modal-body-padding-bottom-screen-jumbo @modal-body-padding-left-screen-jumbo;
    }
  }
}
