& when (@services-view-enabled) {

  button[data-cy='primaryDropdownButton'] {
    color: #fff;
  }

  .modal-service-delete-force {
    cursor: pointer;

    input {
      margin-right: 5px;
    }
  }

  .services-sidebar {
    flex: 1 1 auto;
  }

  .services-table {
    flex: 1 1 auto;
  }

  .services-version-select {

    &-toggle {

      .services-version-select {

        &-icon {

          &-selected {
            display: none;
          }
        }
      }
    }

    &-menu {

      .badge-container {
        display: inline-flex;
      }

      .services-version-select {

        &-icon {

          &-selected {
            visibility: hidden;
          }
        }
      }

      .is-selected {

        .services-version-select {

          &-icon {

            &-selected {
              visibility: visible;
            }
          }
        }
      }
    }

    &-icon {
      margin-right: @base-spacing-unit * 1/4;

      &-selected {
        visibility: hidden;
      }
    }

    .badge-container {
      align-items: center;
    }
  }

  .services-version-text {
    min-width: 0;
    width: auto;
  }

  .service-version-dropdown-wrapper {
    width: 100%;
  }
}

.filter-tasks-bar {

  .filter-bar,
  .filter-bar-left,
  .filter-bar-item {
    width: 100%;
  }

  .filter-input-text {
    height: calc(100% - 2px);
  }
}

@media (min-width: @layout-screen-medium-min-width) {

  .filter-tasks-bar {

    .filter-bar {
      height: 70px;
      position: relative;
    }

    .filter-bar-left {
      left: 0;
      padding-right: 200px;
      position: absolute;
      top: 0;
    }
  }
}

& when (@services-view-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .services-version-select {

      &-icon {
        margin-right: @base-spacing-unit-screen-small * 1/4;
      }
    }
  }
}

& when (@services-view-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .services-version-select {

      &-icon {
        margin-right: @base-spacing-unit-screen-medium * 1/4;
      }
    }
  }
}

& when (@services-view-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .services-sidebar {
      max-width: @service-sidebar-width-screen-large;
    }

    .services-version-select {

      &-icon {
        margin-right: @base-spacing-unit-screen-large * 1/4;
      }
    }
  }
}

& when (@services-view-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .services-sidebar {
      max-width: @service-sidebar-width-screen-jumbo;
    }

    .services-version-select {

      &-icon {
        margin-right: @base-spacing-unit-screen-jumbo * 1/4;
      }
    }
  }
}
