& when (@jobs-view-enabled) {

  .job-details-header-status {
    display: inline-block;
    font-weight: 700;
    margin-right: @base-spacing-unit * 1/2;
  }

  .job-run-history-table-column {

    &-finished,
    &-started {
      display: none;
      width: 140px;
    }

    &-checkbox {
      width: 30px;
    }

    &-id {
      min-width: 200px;
    }

    &-status {
      width: 120px;
    }

    &-run-time {
      width: 100px;
    }

    &-actions {
      min-width: 20px;

      .actions-dropdown {
        float: right;
      }
    }
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .job-details-header-status {
      margin-right: @base-spacing-unit-screen-small * 1/2;
    }
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .job-details-header-status {
      margin-right: @base-spacing-unit-screen-medium * 1/2;
    }

    .job-run-history-table-column {

      &-finished {
        display: table-cell;

        col& {
          display: table-column;
        }
      }
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .job-details-header-status {
      margin-right: @base-spacing-unit-screen-large * 1/2;
    }

    .job-run-history-table-column {

      &-started {
        display: table-cell;

        col& {
          display: table-column;
        }
      }
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .job-details-header-status {
      margin-right: @base-spacing-unit-screen-jumbo * 1/2;
    }
  }
}
