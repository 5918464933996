& when (@toggle-button-enabled) {

  .toggle-button {
    display: none;

    & + span {
      cursor: pointer;
      padding-right: @toggle-width + @base-spacing-unit / 2;
      position: relative;
      transition: color @toggle-transition-speed;

      &:before {
        border: @toggle-border-width solid rgba(0, 0, 0, 0.2);
        border-radius: @toggle-height;
        content: '';
        display: block;
        height: @toggle-height;
        position: absolute;
        right: 0;
        top: 0;
        transition:
          border-color @toggle-transition-speed,
          background-color @toggle-transition-speed;
        width: @toggle-width;
      }

      &:after {
        background-color: @white;
        border-radius: @toggle-height;
        box-shadow: 0 @toggle-border-width @toggle-border-width @toggle-border-width rgba(0, 0, 0, 0.1);
        content: '';
        display: block;
        height: @toggle-height - @toggle-border-width * 2;
        position: absolute;
        right: @toggle-border-width;
        top: @toggle-border-width;
        transform: translateX(@toggle-height - @toggle-width);
        transition: transform @toggle-transition-speed;
        width: @toggle-height - @toggle-border-width * 2;
      }
    }

    &:checked + span {

      &:before {
        background-color: @toggle-checked-color;
        border-color: @toggle-checked-color;
      }

      &:after {
        transform: translateX(0);
      }
    }

    &.toggle-button-align-left {

      & + span {
        padding-left: @toggle-width + @base-spacing-unit / 2;

        &:before {
          left: 0;
          right: auto;
        }

        &:after {
          left: @toggle-border-width + @toggle-height / 2;
          right: auto;
        }
      }
    }
  }
}

& when (@toggle-button-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .toggle-button {

      & + span {
        padding-right: @toggle-width + @base-spacing-unit-screen-small / 2;
      }

      &.toggle-button-align-left {

        & + span {
          padding-right: @toggle-width + @base-spacing-unit-screen-small / 2;
        }
      }
    }
  }
}

& when (@toggle-button-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .toggle-button {

      & + span {
        padding-right: @toggle-width + @base-spacing-unit-screen-medium / 2;
      }

      &.toggle-button-align-left {

        & + span {
          padding-left: @toggle-width + @base-spacing-unit-screen-medium / 2;
        }
      }
    }
  }
}

& when (@toggle-button-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .toggle-button {

      & + span {
        padding-right: @toggle-width + @base-spacing-unit-screen-large / 2;
      }

      &.toggle-button-align-left {

        & + span {
          padding-left: @toggle-width + @base-spacing-unit-screen-large / 2;
        }
      }
    }
  }
}

& when (@toggle-button-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .toggle-button {

      & + span {
        padding-right: @toggle-width + @base-spacing-unit-screen-jumbo / 2;
      }

      &.toggle-button-align-left {

        & + span {
          padding-left: @toggle-width + @base-spacing-unit-screen-jumbo / 2;
        }
      }
    }
  }
}
