.languageDropdown-wrapper {

  .dropdown,
  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
  }
}
