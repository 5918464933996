& when (@fill-enabled) {

  // Light
  .fill-light {
    .element-shape-style(fill-light);
  }

  // Dark
  .fill-dark {
    .element-shape-style(fill-dark);
  }
}
