.page-body {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  z-index: @z-index-page-body;

  &-content {

    &,
    .flex-item-shrink-1 {
      min-height: 1px;
    }

    .container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
