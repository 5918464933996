[data-cy='borderedList'] li {
  padding: 0.5em 0 !important;
  margin: 0 !important;
  &:before {
    display: none;
  }
}

& when (@list-enabled) {

  .list {
    width: 100%;

    &.list-narrow {

      li {
        padding-left: @base-spacing-unit * 1/2;
      }
    }

    // TODO: Come up with a better class to provide this type of style.
    .list-item {
      align-items: center;
      border-bottom: 1px solid @panel-cell-border-bottom-color;
      display: flex;
      margin: 0;
      padding: (@base-spacing-unit * 1/4) 0;

      &:last-child {
        border-bottom: none;
      }
    }

    &.list-inline {

      & > li {
        display: inline-block;
        float: none;
        vertical-align: baseline;
      }

      .list-item-aligned-right {
        float: right;
      }
    }
  }

  .list-inline-separator {
    margin: 0 @base-spacing-unit * 1/6;
    vertical-align: middle;
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .list {

      &.list-narrow {

        li {
          padding-left: @base-spacing-unit-screen-small * 1/2;
        }
      }

      .list-item {
        padding: (@base-spacing-unit-screen-small * 1/4) 0;
      }
    }

    .list-inline-separator {
      margin: 0 @base-spacing-unit-screen-small * 1/6;
    }
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .list {

      &.list-narrow {

        li {
          padding-left: @base-spacing-unit-screen-medium * 1/2;
        }
      }

      .list-item {
        padding: (@base-spacing-unit-screen-medium * 1/4) 0;
      }
    }

    .list-inline-separator {
      margin: 0 @base-spacing-unit-screen-medium * 1/6;
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .list {

      &.list-narrow {

        li {
          padding-left: @base-spacing-unit-screen-large * 1/2;
        }
      }

      .list-item {
        padding: (@base-spacing-unit-screen-large * 1/4) 0;
      }
    }

    .list-inline-separator {
      margin: 0 @base-spacing-unit-screen-large * 1/6;
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .list {

      &.list-narrow {

        li {
          padding-left: @base-spacing-unit-screen-jumbo * 1/2;
        }
      }

      .list-item {
        padding: (@base-spacing-unit-screen-jumbo * 1/4) 0;
      }
    }

    .list-inline-separator {
      margin: 0 @base-spacing-unit-screen-jumbo * 1/6;
    }
  }
}
