& when (@checkbox-table-enabled) {

  .table-form-checkbox {
    display: inline-block;
    vertical-align: middle;

    .form-element-checkbox {
      margin-bottom: 0;
    }
  }
}
