& when (@modal-full-screen-enabled) {

  .modal-full-screen {
    border-radius: 0;
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    top: 0;
    transform: none;
    width: 100%;

    .modal-header {
      background: color-lighten(@neutral, 95%);
      padding: 0;
    }

    .modal-body-wrapper {
      display: flex;
      flex-direction: column;
    }

    .modal-body,
    .modal-body-padding-surrogate {
      padding:
        @modal-full-screen-body-padding-vertical
        @modal-full-screen-body-padding-horizontal;
    }

    .modal-body {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      min-height: 100%;
      overflow: hidden;
    }

    .splitPanels {
      flex-grow: 1;
    }

    .modal-body-offset {
      margin: -@modal-full-screen-body-padding-vertical -@modal-full-screen-body-padding-horizontal;
    }

    .gm-scrollbar-container {
      flex: 1 1 auto;
      height: auto;
    }
  }

  .modal-full-screen-fill-body {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .modal-full-screen-header {
    align-items: center;
    display: flex;
  }

  .modal-full-screen-header-title {
    color: @modal-full-screen-header-title-color;
    flex: 1 1 auto;
    font-size: @modal-full-screen-header-title-font-size;
    font-weight: @modal-full-screen-header-title-font-weight;
    line-height: @modal-full-screen-header-title-line-height;
    height: @modal-full-screen-header-title-line-height;
    text-align: center;
    .small {
      margin: 0;
    }
  }

  .modal-full-screen-actions {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    z-index: 1;
  }

  .modal-full-screen-actions-primary {
    margin-left: @base-spacing-unit * 1/2;
  }

  .modal-full-screen-actions-secondary {
    margin-right: @base-spacing-unit * 1/2;
  }
}

& when (@modal-full-screen-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .modal-full-screen {

      .modal-body,
      .modal-body-padding-surrogate {
        padding:
          @modal-full-screen-body-padding-vertical-screen-small
          @modal-full-screen-body-padding-horizontal-screen-small;
      }

      .modal-body-offset {
        margin: -@modal-full-screen-body-padding-vertical-screen-small -@modal-full-screen-body-padding-horizontal-screen-small;
      }
    }

    .modal-full-screen-actions {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .modal-full-screen-actions-primary {
      margin-left: 0;
      right: @pod-margin-right-screen-small;
    }

    .modal-full-screen-actions-secondary {
      left: @pod-margin-left-screen-small;
      margin-right: 0;
    }
  }
}

& when (@modal-full-screen-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .modal-full-screen {

      .modal-body,
      .modal-body-padding-surrogate {
        padding:
          @modal-full-screen-body-padding-vertical-screen-medium
          @modal-full-screen-body-padding-horizontal-screen-medium;
      }

      .modal-body-offset {
        margin: -@modal-full-screen-body-padding-vertical-screen-medium -@modal-full-screen-body-padding-horizontal-screen-medium;
      }
    }

    .modal-full-screen-actions-primary {
      right: @pod-margin-right-screen-medium;
    }

    .modal-full-screen-actions-secondary {
      left: @pod-margin-left-screen-medium;
    }
  }
}

& when (@modal-full-screen-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .modal-full-screen {

      .modal-body,
      .modal-body-padding-surrogate {
        padding:
          @modal-full-screen-body-padding-vertical-screen-large
          @modal-full-screen-body-padding-horizontal-screen-large;
      }

      .modal-body-offset {
        margin: -@modal-full-screen-body-padding-vertical-screen-large -@modal-full-screen-body-padding-horizontal-screen-large;
      }
    }

    .modal-full-screen-header-title {
      line-height: @modal-full-screen-header-title-line-height-screen-large;
    }

    .modal-full-screen-actions-primary {
      right: @pod-margin-right-screen-large;
    }

    .modal-full-screen-actions-secondary {
      left: @pod-margin-left-screen-large;
    }
  }
}

& when (@modal-full-screen-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .modal-full-screen {

      .modal-body,
      .modal-body-padding-surrogate {
        padding:
          @modal-full-screen-body-padding-vertical-screen-jumbo
          @modal-full-screen-body-padding-horizontal-screen-jumbo;
      }

      .modal-body-offset {
        margin: -@modal-full-screen-body-padding-vertical-screen-jumbo -@modal-full-screen-body-padding-horizontal-screen-jumbo;
      }
    }

    .modal-full-screen-actions-primary {
      right: @pod-margin-right-screen-jumbo;
    }

    .modal-full-screen-actions-secondary {
      left: @pod-margin-left-screen-jumbo;
    }
  }
}
