/* Example Flex Container */

.example-flex-container {
  margin-left: -@grid-gutter-width * 0.5;
  margin-top: -@grid-gutter-width * 0.5;
  position: relative;
}

/* Example Flex Item */

.example-flex-item {
  align-items: center;
  background: #9fc4ea;
  color: color-lighten(#9fc4ea, -70);
  display: flex;
  font-weight: 600;
  height: 50;
  justify-content: center;
  margin-left: @grid-gutter-width * 0.5;
  margin-top: @grid-gutter-width * 0.5;
}

/* Flex Direction */

.example-flex-direction {

  .example-flex-item {
    height: 50;
  }

  .example-flex-item-1 {
    width: 15%;
  }

  .example-flex-item-2 {
    width: 30%;
  }

  .example-flex-item-3 {
    width: 10%;
  }

  .example-flex-item-4 {
    width: 15%;
  }
}

/* Flex Wrap Items */

.example-flex-wrap-items {

  .example-flex-item-1 {
    flex-basis: 20%;
  }

  .example-flex-item-2 {
    flex-basis: 30%;
  }

  .example-flex-item-3 {
    flex-basis: 20%;
  }

  .example-flex-item-4 {
    flex-basis: 40%;
  }

  .example-flex-item-5 {
    flex-basis: 20%;
  }
}

/* Flex Align Items */

.example-flex-align-items {

  .example-flex-item-1 {
    height: 50;
    width: 15%;
  }

  .example-flex-item-2 {
    height: 90;
    width: 15%;
  }

  .example-flex-item-3 {
    height: 30;
    width: 15%;
  }

  .example-flex-item-4 {
    height: 50;
    width: 15%;
  }
}

/* Flex Align Content */

.example-flex-align-content {
  height: 180;

  .example-flex-item-1 {
    flex-basis: 20%;
  }

  .example-flex-item-2 {
    flex-basis: 30%;
  }

  .example-flex-item-3 {
    flex-basis: 20%;
  }

  .example-flex-item-4 {
    flex-basis: 40%;
  }

  .example-flex-item-5 {
    flex-basis: 20%;
  }
}

/* Flex Item Align */

.example-flex-item-align {

  .example-flex-item-1 {
    height: 50;
    width: 15%;
  }

  .example-flex-item-2 {
    height: 90;
    width: 15%;
  }

  .example-flex-item-3 {
    height: 30;
    width: 15%;
  }

  .example-flex-item-4 {
    height: 50;
    width: 15%;
  }
}

/* Flex Item Order */

.example-flex-item-order {

  .example-flex-item {
    height: 50;
  }

  .example-flex-item-1 {
    width: 15%;
  }

  .example-flex-item-2 {
    width: 30%;
  }

  .example-flex-item-3 {
    width: 10%;
  }

  .example-flex-item-4 {
    width: 15%;
  }
}

/* Flex Basis */

.example-flex-item-basis {

  .example-flex-item {
    height: 50;
  }
}

/* Flex Responsive */

.example-flex-responsive {

  .example-flex-item {
    height: 50;
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    // Example Flex Container

    .example-flex-container {
      margin-left: -@grid-gutter-width-screen-small * 0.5;
      margin-top: -@grid-gutter-width-screen-small * 0.5;
    }

    // Example Flex Item

    .example-flex-item {
      margin-left: @grid-gutter-width-screen-small * 0.5;
      margin-top: @grid-gutter-width-screen-small * 0.5;
    }
  }
}

/* Flex Direction */

.example-flex-justify-items {

  .example-flex-item {
    height: 50;
  }

  .example-flex-item-1 {
    width: 15%;
  }

  .example-flex-item-2 {
    width: 20%;
  }

  .example-flex-item-3 {
    width: 10%;
  }

  .example-flex-item-4 {
    width: 15%;
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    // Example Flex Container

    .example-flex-container {
      margin-left: -@grid-gutter-width-screen-medium * 0.5;
      margin-top: -@grid-gutter-width-screen-medium * 0.5;
    }

    // Example Flex Item

    .example-flex-item {
      margin-left: @grid-gutter-width-screen-medium * 0.5;
      margin-top: @grid-gutter-width-screen-medium * 0.5;
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    // Example Flex Container

    .example-flex-container {
      margin-left: -@grid-gutter-width-screen-large * 0.5;
      margin-top: -@grid-gutter-width-screen-large * 0.5;
    }

    // Example Flex Item

    .example-flex-item {
      margin-left: @grid-gutter-width-screen-large * 0.5;
      margin-top: @grid-gutter-width-screen-large * 0.5;
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    // Example Flex Container
    .example-flex-container {
      margin-left: -@grid-gutter-width-screen-jumbo * 0.5;
      margin-top: -@grid-gutter-width-screen-jumbo * 0.5;
    }

    // Example Flex Item

    .example-flex-item {
      margin-left: @grid-gutter-width-screen-jumbo * 0.5;
      margin-top: @grid-gutter-width-screen-jumbo * 0.5;
    }
  }
}
