.json-editor-loading {
  background: @white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.json-editor-loading--inset {
  border: 1px solid @default-border-color;
}

.json-editor-loading--side-panel {
  border-left: 1px solid @default-border-color;
}
