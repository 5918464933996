& when (@tooltip-enabled) {

  .tooltip {
    max-width: 250px;
    will-change: opacity, transform, visibility;
    z-index: @z-index-tooltip;

    .tooltip-content {
      background: @tooltip-background-color;
      border-radius: @tooltip-border-radius;
      color: @white;
      font-size: @tooltip-font-size;
      // Autoprefixer doesn't transform these font-smoothing properties because
      // the property has not yet been accepted in the CSS spec. If it becomes
      // part of the CSS spec, Stylelint will complain about these prefixes.
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      padding: @tooltip-padding-vertical @tooltip-padding-horizontal;
      transition: translateZ(0);

      // Provides an extra level of specificity to override cnvs defaults
      &,
      p {
        color: @white;
        font-size: @tooltip-font-size;
      }

      &:after {
        border-color: @tooltip-background-color;
      }

      .tooltip-line-item {
        padding-bottom: @base-spacing-unit / 6;
        padding-top: @base-spacing-unit / 6;
      }
    }
  }
}

& when (@tooltip-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .tooltip {

      .tooltip-content {

        .tooltip-line-item {
          padding-bottom: @base-spacing-unit-screen-small / 6;
          padding-top: @base-spacing-unit-screen-small / 6;
        }
      }
    }
  }
}

& when (@tooltip-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .tooltip {

      .tooltip-content {

        .tooltip-line-item {
          padding-bottom: @base-spacing-unit-screen-medium / 6;
          padding-top: @base-spacing-unit-screen-medium / 6;
        }
      }
    }
  }
}

& when (@tooltip-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .tooltip {

      .tooltip-content {

        .tooltip-line-item {
          padding-bottom: @base-spacing-unit-screen-large / 6;
          padding-top: @base-spacing-unit-screen-large / 6;
        }
      }
    }
  }
}

& when (@tooltip-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .tooltip {

      .tooltip-content {

        .tooltip-line-item {
          padding-bottom: @base-spacing-unit-screen-jumbo / 6;
          padding-top: @base-spacing-unit-screen-jumbo / 6;
        }
      }
    }
  }
}

// TODO: Audit the need for this.
.json-editor-help {
  position: absolute;
  right: @base-spacing-unit;
  top: @base-spacing-unit;
  z-index: @z-index-json-editor-help-tooltip;
}
