& when (@service-table-enabled) {

  .table {

    .service-table-column-actions {
      text-align: right;
    }
  }

  .service-table {
    table-layout: fixed;

    .service-breadcrumb {
      display: inline-grid;

      .table-cell-link-secondary {
        color: inherit;
        font-size: 12px;
        text-decoration: inherit;
      }
    }

    &-column {

      &-cpus,
      &-gpus,
      &-mem,
      &-disk {
        display: none;

        td&,
        th& {
          text-align: right;
        }
      }

      &-instances {

        td&,
        th& {
          text-align: center;
        }
      }
    }

    .service-status-icon-wrapper {
      min-width: 110px;
    }

    .service-status-progressbar-wrapper {
      margin-left: 30px;
    }

    .status-bar-text {
      margin-left: 7px;
      white-space: nowrap;
    }
  }

  .status-waiting-indicator {
    margin-left: @base-spacing-unit * 1/4;
  }
}

& when (@service-table-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .service-table {

      .status-bar-indicator {
        display: none;
      }
    }

    .status-waiting-indicator {
      margin-left: @base-spacing-unit-screen-small * 1/4;
    }
  }
}

& when (@service-table-enabled) and (@layout-screen-medium-enabled) {
  // Media introduced so we can use the space the most and only hide at a very specific width
  @media (max-width: @layout-screen-medium-max-width) {

    .service-status-progressbar-wrapper {
      display: none;
    }
  }

  @media (min-width: @layout-screen-medium-min-width) {

    .service-table {

      &-column {

        &-cpus,
        &-gpus,
        &-mem,
        &-disk {
          display: table-cell;

          col& {
            display: table-column;
          }
        }
      }

      .status-bar-wrapper {
        white-space: nowrap;
      }
    }

    .status-waiting-indicator {
      margin-left: @base-spacing-unit-screen-medium * 1/4;
    }
  }
}

& when (@service-table-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .service-table {

      .status-bar-indicator {
        display: inline;
      }

      .service-status-progressbar-wrapper {
        margin-left: 5px;
      }
    }

    .status-waiting-indicator {
      margin-left: @base-spacing-unit-screen-large * 1/4;
    }
  }
}

& when (@service-table-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .service-table {

      .service-status-progressbar-wrapper {
        display: inline-block;
      }
    }

    .status-waiting-indicator {
      margin-left: @base-spacing-unit-screen-jumbo * 1/4;
    }
  }
}
