& when (@icon-tiny-enabled) {
  /* Icon tiny (.icon-tiny) */

  .icon-tiny {
    .element-spacing(icon-tiny, null, null);
    .property-variant(icon-tiny, width, null, null);
    .property-variant(icon-tiny, height, null, null);
    .property-variant(icon-tiny, border-radius, null, null);
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    & when (@icon-tiny-enabled) and (@icon-tiny-screen-small-enabled) {
      /* Icon tiny (.icon-tiny) */

      .icon-tiny {
        .element-spacing(icon-tiny, null, screen-small);
        .property-variant(icon-tiny, width, null, screen-small);
        .property-variant(icon-tiny, height, null, screen-small);
        .property-variant(icon-tiny, border-radius, null, screen-small);
      }
    }
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    & when (@icon-tiny-enabled) and (@icon-tiny-screen-medium-enabled) {
      /* Icon tiny (.icon-tiny) */

      .icon-tiny {
        .element-spacing(icon-tiny, null, screen-medium);
        .property-variant(icon-tiny, width, null, screen-medium);
        .property-variant(icon-tiny, height, null, screen-medium);
        .property-variant(icon-tiny, border-radius, null, screen-medium);
      }
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    & when (@icon-tiny-enabled) and (@icon-tiny-screen-large-enabled) {
      /* Icon tiny (.icon-tiny) */

      .icon-tiny {
        .element-spacing(icon-tiny, null, screen-large);
        .property-variant(icon-tiny, width, null, screen-large);
        .property-variant(icon-tiny, height, null, screen-large);
        .property-variant(icon-tiny, border-radius, null, screen-large);
      }
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    & when (@icon-tiny-enabled) and (@icon-tiny-screen-jumbo-enabled) {
      /* Icon tiny (.icon-tiny) */

      .icon-tiny {
        .element-spacing(icon-tiny, null, screen-jumbo);
        .property-variant(icon-tiny, width, null, screen-jumbo);
        .property-variant(icon-tiny, height, null, screen-jumbo);
        .property-variant(icon-tiny, border-radius, null, screen-jumbo);
      }
    }
  }
}
