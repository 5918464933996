& when (@field-input-enabled) {

  input.field-input-number-narrow {
    width: 25%;
  }

  .field-input-text-narrow {
    width: 50%;
  }

  & when (@layout-screen-small-enabled) {

    @media (max-width: @layout-screen-small-min-width) {

      input.field-input-number-narrow {
        width: 50%;
      }

      .field-input-text-narrow {
        width: 100%;
      }
    }
  }
}
