.errorsAlert-listItem {
  color: inherit;

  &:before {
    background-color: currentColor;
  }
}

.errorsAlert-message {
  padding-left: @base-spacing-unit * 1/2;
}

.errorsAlert-list {
  margin-bottom: 0;
}
