& when (@buttons-enabled) {

  // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion_r71219313
  .go-to-bottom-button {
    bottom: 32px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .button {

    &-up-arrow,
    &-down-arrow {
      position: relative;

      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: '';
        display: inline-block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &-up-arrow {

      &:after {
        border-bottom: 6px solid color-lighten(@neutral, 10);
      }
    }

    &-down-arrow {

      &:after {
        border-top: 6px solid color-lighten(@neutral, 10);
      }
    }

    &-transparent {

      &,
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }

    &.flush {
      padding: 0;
    }

    &.flush-top {
      padding-top: 0;
    }

    &.flush-right {
      padding-right: 0;
    }

    &.flush-bottom {
      padding-bottom: 0;
    }

    &.flush-left {
      padding-left: 0;
    }
  }

  .button-collection {

    // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion_r71220184
    & > .dropdown {
      margin-left: @button-collection-margin-horizontal;
      margin-top: @button-collection-margin-vertical;
      vertical-align: middle;
    }

    &.button-collection-stacked {
      margin: 0;

      .button {
        margin-left: 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion_r71220271
  .button-group {
    margin-bottom: @base-spacing-unit * 0.5;

    // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion-diff-71220439
    .dot {
      margin-right: @base-spacing-unit * 0.25;
    }

    .badge {
      margin-left: @base-spacing-unit * 0.25;
    }

    // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion_r71220726
    &-directions {
      white-space: nowrap;

      .button {
        background: color-lighten(@neutral, 90);
        display: inline-block;
        float: none;
      }
    }
  }

  // TODO: https://github.com/dcos/dcos-ui/pull/741#discussion-diff-71220632
  .button-split-content {
    text-align: left;

    .button-split-content-wrapper {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;
    }

    .button-split-content-item {
      flex: 0 0 auto;
    }

    .button-split-content-label {
      flex: 1 1 auto;
      text-align: left;
    }

    &:after {
      align-self: center;
      flex: 0 0 auto;
    }
  }

  .button-link.active {
    background-color: @grey-light-lighten-3;
  }
}

& when (@buttons-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .button {

      &.button-fixed-width {

        &.button-fixed-width-small {
          width: @base-spacing-unit-screen-small * 3;
        }
      }
    }

    .button-collection {

      & > .dropdown {
        margin-left: @button-collection-margin-horizontal-screen-small;
        margin-top: @button-collection-margin-vertical-screen-small;
      }
    }

    .button-group {
      margin-bottom: @base-spacing-unit-screen-small * 0.5;

      .dot {
        margin-right: @base-spacing-unit-screen-small * 0.25;
      }

      .badge {
        margin-left: @base-spacing-unit-screen-small * 0.25;
      }
    }
  }
}

& when (@buttons-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .button {

      &.button-fixed-width {

        &.button-fixed-width-small {
          width: @base-spacing-unit-screen-medium * 3;
        }
      }
    }

    .button-collection {

      & > .dropdown {
        margin-left: @button-collection-margin-horizontal-screen-medium;
        margin-top: @button-collection-margin-vertical-screen-medium;
      }
    }

    .button-group {
      margin-bottom: @base-spacing-unit-screen-medium * 0.5;

      .dot {
        margin-right: @base-spacing-unit-screen-medium * 0.15;
      }

      .badge {
        margin-left: @base-spacing-unit-screen-medium * 0.15;
      }
    }
  }
}

& when (@buttons-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .button {

      &.button-fixed-width {

        &.button-fixed-width-small {
          width: @base-spacing-unit-screen-large * 3;
        }
      }
    }

    .button-collection {

      & > .dropdown {
        margin-left: @button-collection-margin-horizontal-screen-large;
        margin-top: @button-collection-margin-vertical-screen-large;
      }
    }

    .button-group {
      margin-bottom: @base-spacing-unit-screen-large * 0.5;

      .dot {
        margin-right: @base-spacing-unit-screen-large * 0.15;
      }

      .badge {
        margin-left: @base-spacing-unit-screen-large * 0.15;
      }
    }
  }
}

& when (@buttons-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .button {

      &.button-fixed-width {

        &.button-fixed-width-small {
          width: @base-spacing-unit-screen-jumbo * 3;
        }
      }
    }

    .button-collection {

      & > .dropdown {
        margin-left: @button-collection-margin-horizontal-screen-jumbo;
        margin-top: @button-collection-margin-vertical-screen-jumbo;
      }
    }

    .button-group {
      margin-bottom: @base-spacing-unit-screen-jumbo * 0.5;

      .dot {
        margin-right: @base-spacing-unit-screen-jumbo * 0.15;
      }

      .badge {
        margin-left: @base-spacing-unit-screen-jumbo * 0.15;
      }
    }
  }
}
