& when (@code-enabled) {

  & when (@code-block-prettyprint-enabled) {

    pre.prettyprint {
      border: @code-block-prettyprint-border-width @code-block-prettyprint-border-style @code-block-prettyprint-border-color;
      border-radius: @code-border-radius;
      border-style: @code-block-prettyprint-border-style;
      flex-basis: @code-block-prettyprint-flex-basis;

      &.inverse {
        border-color: @code-block-prettyprint-border-color-inverse;
      }
    }
  }

  .code-copy-wrapper {
    overflow: hidden;
    position: relative;

    .code-copy-icon {
      display: none;
    }

    &:hover {

      .code-copy-icon {
        display: inline-block;
        margin-left: 1em;

        .icon.icon-grey {

          &:hover {
            color: color-lighten(@neutral, 20%);
          }
        }
      }

      .code-copy-icon.tight {
        right: 0;
        top: 0;
      }
    }
  }

  @media (min-width: @layout-screen-medium-min-width) {

    .code-copy-wrapper {

      &:hover {

        .code-copy-icon {
          right: @code-block-padding-right-screen-medium;
          top: calc(@code-block-padding-top-screen-medium ~' - 3px');
        }

        .code-copy-icon.tight {
          right: 0;
          top: 0;
        }
      }
    }
  }

  @media (min-width: @layout-screen-large-min-width) {

    .code-copy-wrapper {

      &:hover {

        .code-copy-icon {
          right: @code-block-padding-right-screen-large;
          top: calc(@code-block-padding-top-screen-large ~' - 3px');
        }

        .code-copy-icon.tight {
          right: 0;
          top: 0;
        }
      }
    }
  }
}
