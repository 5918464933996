@expanding-table-line-height: 1.8rem;

& when (@expanding-table-enabled) {

  .expanding-table {

    td {
      line-height: @expanding-table-line-height;
      white-space: nowrap;

      // All children in an expanding table's cell needs to have the same
      // line-height to maintain the illusion of expanding one table row into
      // many.
      * {
        line-height: inherit;
      }
    }
  }

  .expanding-table-primary-cell {
    color: @neutral;
    font-weight: 700;
    margin: @base-spacing-unit * -1/4 0;
    padding: @base-spacing-unit * 1/4 @base-spacing-unit * 1/2;

    &:hover {
      text-decoration: underline;
    }

    &.is-expandable {
      cursor: pointer;
      position: relative;

      &:before {
        border-bottom: 4px solid transparent;
        border-left: 5px solid @neutral;
        border-top: 4px solid transparent;
        content: '';
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.is-expanded {

      &:before {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .expanding-table-primary-cell-heading {
    font-weight: 300;
    padding-left: @base-spacing-unit * 1.25;
    position: relative;

    &:before,
    &:after {
      background: color-lighten(@neutral, 80);
      content: '';
      height: 100%;
      left: @base-spacing-unit * 3/4 + 1px;
      position: absolute;
      top: 0;
      width: 1px;
    }

    &:before {
      height: 1px;
      top: 50%;
      width: @base-spacing-unit * 3/10;
    }

    &--no-nested-indicator {
      padding-left: @base-spacing-unit * 1/2;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .expanding-table-child {

    > div {
      height: 25px;
    }

    &:last-child {

      .expanding-table-primary-cell-heading {

        &:after {
          height: 50%;
        }
      }
    }
  }
}
