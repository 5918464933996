& when (@secret-value-enabled) {

  .secret-value .secret-toggle {
    margin-left: @base-spacing-unit * 0.5;
  }
}

& when (@secret-value-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .secret-value .secret-toggle {
      margin-left: @base-spacing-unit-screen-small * 0.5;
    }
  }
}

& when (@secret-value-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .secret-value .secret-toggle {
      margin-left: @base-spacing-unit-screen-medium * 0.5;
    }
  }
}

& when (@secret-value-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .secret-value .secret-toggle {
      margin-left: @base-spacing-unit-screen-large * 0.5;
    }
  }
}

& when (@secret-value-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .secret-value .secret-toggle {
      margin-left: @base-spacing-unit-screen-jumbo * 0.5;
    }
  }
}

.secret-toggle {
  margin-right: 4px;
}

.secret-file-value {

  > * + * {
    margin-left: 0.5em;
  }
}
