// TODO: Abstract this into bordered-list or something similar.
& when (@dashboard-health-list-enabled) {

  .dashboard-health-list {
    flex: 1 1 auto;
    max-width: 100%;

    .list {

      .list-item {
        padding: @base-spacing-unit * 1/3 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        color: @neutral;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .dashboard-health-list-item-description {
    flex: 1 1 auto;
  }

  .dashboard-health-list-health-label {
    flex: 0 0 auto;
    margin-left: @base-spacing-unit * 1/2;
    text-align: right;
  }
}

& when (@status-dots-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .dashboard-health-list {

      .list {

        .list-item {
          padding: @base-spacing-unit-screen-small * 1/3 0;
        }
      }
    }
  }
}

& when (@status-dots-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .dashboard-health-list {

      .list {

        .list-item {
          padding: @base-spacing-unit-screen-medium * 1/3 0;
        }
      }
    }
  }
}

& when (@status-dots-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .dashboard-health-list {

      .list {

        .list-item {
          padding: @base-spacing-unit-screen-large * 1/3 0;
        }
      }
    }
  }
}

& when (@status-dots-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .dashboard-health-list {

      .list {

        .list-item {
          padding: @base-spacing-unit-screen-jumbo * 1/3 0;
        }
      }
    }
  }
}
