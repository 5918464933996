// TODO: Audit the need for this.
& when (@side-list-enabled) {

  .side-list {
    width: 100%;

    .side-list-item {
      padding-bottom: @base-spacing-unit / 4;
    }
  }
}
