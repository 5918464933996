& when (@form-row-enabled) {

  // When .form-row is used, we remove the margins from .form-group and apply
  // them to the row itself.
  .form-row {
    display: flex;
    margin-bottom: @form-row-margin-bottom;

    & + .form-row {
      margin-top: @form-row-margin-top;
    }

    &:last-child {

      &,
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  // Add top-padding equivalent to a label
  .form-group-without-top-label {
    padding-top: @body-line-height + @label-margin-bottom;
  }

  .form-spacing-top {
    margin-top: @form-row-margin-top;
  }
}

& when (@form-row-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .form-row {
      margin-bottom: @form-row-margin-bottom-screen-small;

      & + .form-row {
        margin-top: @form-row-margin-top-screen-small;
      }
    }
  }
}

& when (@form-row-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .form-row {
      margin-bottom: @form-row-margin-bottom-screen-medium;

      & + .form-row {
        margin-top: @form-row-margin-top-screen-medium;
      }
    }

    // Add top-padding equivalent to a label
    .form-group-without-top-label {
      padding-top: @body-line-height + @label-margin-bottom;
    }

    .form-spacing-top {
      margin-top: @form-row-margin-top-screen-medium;
    }
  }
}

& when (@form-row-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .form-row {
      margin-bottom: @form-row-margin-bottom-screen-large;

      & + .form-row {
        margin-top: @form-row-margin-top-screen-large;
      }
    }

    // Add top-padding equivalent to a label
    .form-group-without-top-label {
      padding-top: @body-line-height + @label-margin-bottom;
    }

    .form-spacing-top {
      margin-top: @form-row-margin-top-screen-large;
    }
  }
}

& when (@form-row-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .form-row {
      margin-bottom: @form-row-margin-bottom-screen-jumbo;

      & + .form-row {
        margin-top: @form-row-margin-top-screen-jumbo;
      }
    }

    .form-spacing-top {
      margin-top: @form-row-margin-top-screen-jumbo;
    }
  }
}
