/* divider Overview */

.layout-box.dividers-spacing-modifiers {

  .layout-box-item {

    &.layout-box-item-content {
      height: 1px;
    }
  }
}

.layout-box.dividers-spacing-direction-modifier {

  .layout-box-item {

    &.layout-box-item-content {
      height: 1px;
    }
  }
}
