.framework-configuration-form fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;

  .error-detail {
    display: none;
  }
}

.pre-install-notes {

  a {
    color: inherit;
  }
}

.framework-form-yaml-editor {
  border: 1px solid @form-control-border-color;
  border-radius: 4px;
  width: 100%;

  .ace_scroller.ace_scroll-left {
    box-shadow: none;
  }

  .ace_gutter {
    z-index: 0;
  }
}

.glyphicon-plus:before {
  content: '+';
}

.glyphicon-plus {
  font-style: normal;
}
