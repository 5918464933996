& when (@packages-view-enabled) {

  .package-action-buttons {
    margin-left: auto;
    text-align: left;

    a {
      color: @small-color;
    }
  }

  .container {

    .filter-bar-item {

      .button-outline {

        span {
          margin: @base-spacing-unit * 1/6;
          padding: 1px 6px;
        }

        &.active {

          span {
            background-color: @white;
            border-color: @white;
          }
        }
      }
    }
  }

  @media (min-width: @layout-screen-medium-min-width) {

    .package-action-buttons {
      text-align: right;
    }
  }

  .install-package-modal-package-notes {
    max-width: 100%;

    // Remove the bottom margin on the last element in the package notes.
    & > * {

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .selected-badge {

    .badge {
      margin-left: 0;
      margin-right: @base-spacing-unit * 1/4;
    }
  }

  .package-version-label {
    padding-right: @base-spacing-unit * 1/4;
  }
}

& when (@packages-view-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .selected-badge {

      .badge {
        margin-right: @base-spacing-unit-screen-small * 1/4;
      }
    }
  }
}

& when (@packages-view-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .selected-badge {

      .badge {
        margin-right: @base-spacing-unit-screen-medium * 1/4;
      }
    }
  }
}

& when (@packages-view-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .selected-badge {

      .badge {
        margin-right: @base-spacing-unit-screen-large * 1/4;
      }
    }
  }
}

& when (@packages-view-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .selected-badge {

      .badge {
        margin-right: @base-spacing-unit-screen-jumbo * 1/4;
      }
    }
  }
}
