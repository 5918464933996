& when (@ace-editor-enabled) {

  .ace-editor-container {
    position: relative;
  }

  .ace_search {
    color: @black;
  }

  .ace_searchbtn {
    padding: 0;
  }
}
