& when (@form-control-toggle-enabled) {

  .form-control-toggle {

    &.disabled {
      cursor: not-allowed;
    }

    .truecase {
      text-transform: none;
    }
  }
}
