// TODO: Thoroughly audit this.
& when (@nodes-grid-view-enabled) {

  .nodes-grid {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .nodes-grid-legend {
      display: flex;
      flex: 0 0 200px;
      flex-direction: column;
      margin-right: @base-spacing-unit * 3/4;

      &.disabled {

        .nodes-grid-service-list {
          opacity: 0.5;
        }
      }

      .nodes-grid-service-list {
        color: @white;
        transition: opacity 0.5s;

        & > li {
          line-height: @base-spacing-unit * 3/4;
        }
      }

      .dot {
        background-color: @neutral;
        display: inline-block;
        margin-right: @base-spacing-unit / 3;

        &.service-color-0 {
          background-color: @resource-cpu-color;
        }

        &.service-color-1 {
          background-color: @yellow;
        }

        &.service-color-2 {
          background-color: @red;
        }

        &.service-color-3 {
          background-color: @resource-disk-color;
        }

        &.service-color-4 {
          background-color: @green;
        }

        &.service-color-5 {
          background-color: @orange;
        }

        &.service-color-6 {
          background-color: @resource-mem-color;
        }

        &.service-color-7 {
          background-color: @cyan;
        }

        &.service-color-8 {
          background-color: @unique-color-1;
        }

        &.service-color-9 {
          background-color: @unique-color-2;
        }

        &.service-color-10 {
          background-color: @unique-color-3;
        }

        &.service-color-11 {
          background-color: @unique-color-4;
        }

        &.service-color-12 {
          background-color: @unique-color-5;
        }

        &.service-color-13 {
          background-color: @unique-color-6;
        }

        &.service-color-14 {
          background-color: @unique-color-7;
        }

        &.service-color-15 {
          background-color: @unique-color-8;
        }

        &.service-color-16 {
          background-color: @unique-color-9;
        }

        &.service-color-17 {
          background-color: @unique-color-10;
        }

        &.service-color-18 {
          background-color: @unique-color-11;
        }

        &.service-color-19 {
          background-color: @unique-color-12;
        }

        &.service-color-20 {
          background-color: @unique-color-13;
        }

        &.service-color-21 {
          background-color: @unique-color-14;
        }

        &.service-color-22 {
          background-color: @unique-color-15;
        }

        &.service-color-23 {
          background-color: @unique-color-16;
        }

        &.service-color-24 {
          background-color: @unique-color-17;
        }

        &.service-color-25 {
          background-color: @unique-color-18;
        }

        &.service-color-26 {
          background-color: @unique-color-19;
        }

        &.service-color-27 {
          background-color: @unique-color-20;
        }

        &.service-color-28 {
          background-color: @unique-color-21;
        }

        &.service-color-29 {
          background-color: @unique-color-22;
        }

        &.service-color-30 {
          background-color: @unique-color-23;
        }

        &.service-color-31 {
          background-color: @unique-color-24;
        }

        &.service-color-32 {
          background-color: color-lighten(@neutral, 40);
        }

        &.service-color-unused {
          background-color: color-lighten(@neutral, 5);
        }
      }
    }

    .nodes-grid-dials {
      flex: 1 1 auto;
    }
  }
}

& when (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .nodes-grid {

      .nodes-grid-legend {
        margin-right: @base-spacing-unit-screen-small * 3/4;

        .nodes-grid-service-list {

          & > li {
            line-height: @base-spacing-unit-screen-small * 3/4;
          }
        }
      }
    }
  }
}

& when (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .nodes-grid {

      .nodes-grid-legend {
        margin-right: @base-spacing-unit-screen-medium * 3/4;

        .nodes-grid-service-list {

          & > li {
            line-height: @base-spacing-unit-screen-medium * 3/4;
          }
        }
      }
    }
  }
}

& when (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .nodes-grid {

      .nodes-grid-legend {
        margin-right: @base-spacing-unit-screen-large * 3/4;

        .nodes-grid-service-list {

          & > li {
            line-height: @base-spacing-unit-screen-large * 3/4;
          }
        }
      }
    }
  }
}

& when (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .nodes-grid {

      .nodes-grid-legend {
        margin-right: @base-spacing-unit-screen-jumbo * 3/4;

        .nodes-grid-service-list {

          & > li {
            line-height: @base-spacing-unit-screen-jumbo * 3/4;
          }
        }
      }
    }
  }
}
