& when (@caret-enabled) {

  .caret {
    border-left: @caret-width-base solid transparent;
    border-right: @caret-width-base solid transparent;
    border-top: @caret-width-base dashed;
    height: 0;
    vertical-align: middle;
    width: 0;

    &.dropup {
      border-bottom: @caret-width-base solid;
      border-top: 0;
    }
  }
}
