& when (@form-elements-enabled) {

  textarea {

    &.form-control {
      height: 125px;
    }
  }

  .form-control-input-height {
    align-items: center;
    display: flex;
    height: @form-control-height;
  }

  .form-control-group-add-on {
    white-space: nowrap;

    .small {
      display: inline-block;
    }

    a {
      vertical-align: middle;
    }
  }

  .form-control-inline {

    // This is necessary to override CNVS specificity.
    &,
    label& {
      display: inline-block;
    }

    & + .form-control-inline {
      margin-top: 4px;
    }
  }

  // TODO: Remove this when the full screen Create Service is implemented.
  .form-control-force-narrow {

    .form-control,
    .button {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .form-control {
    position: relative;

    // TODO: Remove this when the fix hits CNVS:
    // https://github.com/mesosphere/cnvs/issues/76
    &:disabled,
    &.disabled {
      color: color-lighten(@neutral, 50);
    }
  }

  .form-colon {
    left: 100%;
    position: absolute;
    top: 0.5rem;
    // perfectly center the colon visually
    transform: translate(-1px, -1px);
  }

  .form-group-container-action-button-group {
    position: absolute;
    right: 0;
    top: 0;
    z-index: @z-index-form-group-container-action-button-group;
  }
}

& when (@grid-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .form-control-inline {

      & + .form-control-inline {
        margin-bottom: 4px;
        margin-top: 4px;
      }
    }
  }
}

& when (@grid-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .form-control-inline {

      & + .form-control-inline {
        margin-top: 4px;
      }
    }
  }
}

& when (@grid-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .form-control-input-height {
      height: @form-control-height-screen-large;
    }

    .form-control-inline {

      & + .form-control-inline {
        margin-top: 4px;
      }
    }
  }
}

& when (@grid-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .form-control-inline {

      & + .form-control-inline {
        margin-top: 4px;
      }
    }
  }
}
