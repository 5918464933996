& when (@user-dropdown-menu-enabled) {

  .user-account-dropdown {
    display: flex;

    &-list {

      .dropdown-menu-items {
        padding-top: @user-account-dropdown-menu-padding-top;
      }
    }
  }

  .user-account-dropdown-button {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    height: auto;
    justify-content: flex-start;
    min-width: 0;
    padding: @pod-margin-top * @pod-short-margin-top-scale @pod-margin-right * @pod-narrow-margin-right-scale @pod-margin-bottom * @pod-short-margin-bottom-scale @pod-margin-left * @pod-narrow-margin-left-scale;

    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border: none;
    }

    .cluster-header {
      align-items: stretch;
      color: @white;
      // This !important overrides an awful CNVS !important tag.
      // https://github.com/mesosphere/canvas/issues/24
      display: flex !important;
      flex: 1 1 auto;
      flex-direction: column;
      min-width: 0;
      overflow: hidden;
      text-align: left;

      &-primary {
        flex: 0 1 auto;
        min-width: 0;
        overflow: hidden;
        padding-right: @user-account-dropdown-menu-primary-padding-right;

        &-wrapper {
          align-items: center;
          display: flex;
          flex: 1 1 auto;

          & + .cluster-header {

            &-secondary {
              margin-top: @user-account-dropdown-menu-primary-margin-bottom;
            }
          }
        }
      }

      &-secondary {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    .icon {
      fill: @white;
      flex: 0 0 auto;
      transition: fill 0.2s, transform 0.2s;

      .open & {
        transform: rotate(180deg);
      }
    }
  }

  .user-account-dropdown-menu {
    margin-left: @pod-margin-left * @pod-narrow-margin-left-scale;

    li:empty {
      display: none;
    }

    li:empty + .dropdown-menu-section-header {
      margin-top: 0;
    }

    &-public-ip {

      li& {
        padding: 0;
      }

      &:hover {

        .user-account-dropdown-menu {

          &-copy-text {
            visibility: visible;
          }
        }
      }
    }

    &-copy-text {
      color: @purple;
      display: inline-block;
      margin-left: @user-account-dropdown-menu-copy-text-margin-left;
      text-decoration: underline;
      visibility: hidden;
    }
  }
}

& when (@user-dropdown-menu-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .user-account-dropdown-button {
      padding: @pod-margin-top-screen-small * @pod-short-margin-top-scale @pod-margin-right-screen-small * @pod-narrow-margin-right-scale @pod-margin-bottom-screen-small * @pod-short-margin-bottom-scale @pod-margin-left-screen-small * @pod-narrow-margin-left-scale;

      .cluster-header {

        &-primary {

          &-wrapper {
            padding-right: @user-account-dropdown-menu-primary-padding-right-screen-small;

            & + .cluster-header {

              &-secondary {
                margin-top: @user-account-dropdown-menu-primary-margin-bottom-screen-small;
              }
            }
          }
        }
      }
    }

    .user-account-dropdown-menu {
      margin-left: @pod-margin-left-screen-small * @pod-narrow-margin-left-scale;

      &-copy-text {
        margin-left: @user-account-dropdown-menu-copy-text-margin-left-screen-small;
      }
    }
  }
}

& when (@user-dropdown-menu-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .user-account-dropdown-button {
      padding: @pod-margin-top-screen-medium * @pod-short-margin-top-scale @pod-margin-right-screen-medium * @pod-narrow-margin-right-scale @pod-margin-bottom-screen-medium * @pod-short-margin-bottom-scale @pod-margin-left-screen-medium * @pod-narrow-margin-left-scale;

      .cluster-header {

        &-primary {

          &-wrapper {
            padding-right: @user-account-dropdown-menu-primary-padding-right-screen-medium;

            & + .cluster-header {

              &-secondary {
                margin-top: @user-account-dropdown-menu-primary-margin-bottom-screen-medium;
              }
            }
          }
        }
      }
    }

    .user-account-dropdown-menu {
      margin-left: @pod-margin-left-screen-medium * @pod-narrow-margin-left-scale;

      &-copy-text {
        margin-left: @user-account-dropdown-menu-copy-text-margin-left-screen-medium;
      }
    }
  }
}

& when (@user-dropdown-menu-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .user-account-dropdown-button {
      padding: @pod-margin-top-screen-large * @pod-short-margin-top-scale @pod-margin-right-screen-large * @pod-narrow-margin-right-scale @pod-margin-bottom-screen-large * @pod-short-margin-bottom-scale @pod-margin-left-screen-large * @pod-narrow-margin-left-scale;

      .cluster-header {

        &-primary {

          &-wrapper {
            padding-right: @user-account-dropdown-menu-primary-padding-right-screen-large;

            & + .cluster-header {

              &-secondary {
                margin-top: @user-account-dropdown-menu-primary-margin-bottom-screen-large;
              }
            }
          }
        }
      }
    }

    .user-account-dropdown-menu {
      margin-left: @pod-margin-left-screen-large * @pod-narrow-margin-left-scale;

      &-copy-text {
        margin-left: @user-account-dropdown-menu-copy-text-margin-left-screen-large;
      }
    }
  }
}

& when (@user-dropdown-menu-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .user-account-dropdown-button {
      padding: @pod-margin-top-screen-jumbo * @pod-short-margin-top-scale @pod-margin-right-screen-jumbo * @pod-narrow-margin-right-scale @pod-margin-bottom-screen-jumbo * @pod-short-margin-bottom-scale @pod-margin-left-screen-jumbo * @pod-narrow-margin-left-scale;

      .cluster-header {

        &-primary {

          &-wrapper {
            padding-right: @user-account-dropdown-menu-primary-padding-right-screen-jumbo;

            & + .cluster-header {

              &-secondary {
                margin-top: @user-account-dropdown-menu-primary-margin-bottom-screen-jumbo;
              }
            }
          }
        }
      }
    }

    .user-account-dropdown-menu {
      margin-left: @pod-margin-left-screen-jumbo * @pod-narrow-margin-left-scale;

      &-copy-text {
        margin-left: @user-account-dropdown-menu-copy-text-margin-left-screen-jumbo;
      }
    }
  }
}
