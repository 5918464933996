/* Available Class Highlight Colors */

td.is-visible,
td.is-hidden {
  text-align: center;
}

td.is-visible {
  background: color-lighten(@green, 90);
  color: @green;
}

td.is-hidden {
  background: color-lighten(@neutral, 99);
  color: color-lighten(@neutral, 75);
}
