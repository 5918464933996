& when (@typeahead-enabled) {

  .form-control.form-control-group {

    .typeahead {

      .sr-only,
      .rbt-input-hint {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      .dropdown-menu li {
        padding-left: 0;

        &:before {
          display: none;
        }

        &:hover,
        &.active {
          background-color: #f5f5f6;
        }
      }

      .dropdown-item {
        &:extend(.dropdown-menu-list li);
        cursor: pointer;
      }

      .dropdown-menu {
        margin-left: @typeahead-dropdown-menu-margin-left;
        margin-top: @typeahead-dropdown-menu-margin-top;
        min-width: @typeahead-dropdown-menu-width;
        position: absolute;

        .dropdown-menu-list {
          // These extends are all copying dropdown styles from reactjs-components
          li {
            &:extend(.clickable);
            &:extend(.dropdown-menu-list li.is-selectable all);

            &.active {
              &:extend(.dropdown-menu-list li.is-selectable:hover);
            }

            .highlight {
              font-size: inherit;
              line-height: inherit;
            }
          }
        }
      }

      &.inverse {

        .bootstrap-typeahead-input {

          .bootstrap-typeahead-input-hint {
            color: color-lighten(@gray, 30);
          }
        }

        // These extends are all copying dropdown styles from reactjs-components
        .dropdown-menu {
          &:extend(.dropdown-menu.inverse);

          .dropdown-menu-list {
            &:extend(.dropdown-menu-list.inverse);

            li {
              &:extend(.dropdown.open .dropdown-menu.inverse li.is-selectable all);

              &.active {
                &:extend(.dropdown.open .dropdown-menu.inverse li.is-selectable:hover);
              }
            }
          }
        }
      }
    }

    &.form-control-inverse {

      .typeahead {

        .bootstrap-typeahead-input-main {
          &:extend(.form-control-inverse all);
          &:extend(.filter-input-text all);
        }
      }
    }
  }
}

& when (@typeahead-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .form-control-group.filter-input-text-group {

      .typeahead {

        .dropdown-menu {
          // We are matching the width of .filter-input-text-group
          // as defined in reactjs-components
          min-width: 100px;
        }
      }
    }
  }
}

& when (@typeahead-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .form-control-group.filter-input-text-group {

      .typeahead {

        .dropdown-menu {
          // We are matching the width of .filter-input-text-group
          // as defined in reactjs-components
          min-width: 200px;
        }
      }
    }
  }
}
