// TODO: Audit all of these styles.
& when (@charts-enabled) {

  .chart {
    display: flex;
    flex-direction: column;
    transform: translateZ(0);

    .chart-chart {
      flex-grow: 1;
      position: relative;
    }

    svg {
      display: inline-block;

      .background {
        fill: fade(@neutral, 5%);
      }
    }

    .axis path,
    .grid-graph .tick,
    .axis line {
      fill: none;
      shape-rendering: crispEdges;
      stroke: fade(@neutral, 6%);
      stroke-width: 1px;
    }

    .axis text {
      fill: @chart-axis-text-color;

      &.current-value {
        fill: @neutral;

        &.shadow {
          text-shadow: 0 0 4px @white, 0 0 4px @white;
        }
      }
    }

    .unsuccessful-block {
      fill: @black;
    }

    [class*='path-color'] .unsuccessful-block {
      fill: inherit;
      fill-opacity: 1;
    }

    .arc,
    .area,
    .bar rect {

      &.path-color-0 {
        fill: @purple;
        stroke: @purple;
      }

      &.path-color-1 {
        fill: @yellow;
        stroke: @yellow;
      }

      &.path-color-2 {
        fill: @red;
        stroke: @red;
      }

      &.path-color-3 {
        fill: @blue;
        stroke: @blue;
      }

      &.path-color-4 {
        fill: @green;
        stroke: @green;
      }

      &.path-color-5 {
        fill: @orchid;
        stroke: @orchid;
      }

      &.path-color-6 {
        fill: @pink;
        stroke: @pink;
      }

      &.path-color-7 {
        fill: @label-muted-color;
        opacity: 0.2;
        stroke: @label-muted-color;
      }

      &.path-color-8 {
        fill: @cyan;
        stroke: @cyan;
      }
    }

    .area {
      fill-opacity: 0.3;
      stroke-width: 0;
    }

    .arc path {
      stroke: @panel-background-color;
      stroke-width: 4px;
    }

    .line {
      fill: none;
      stroke-width: 2px;

      &.path-color-0 {
        stroke: @purple;
      }

      &.path-color-1 {
        stroke: @yellow;
      }

      &.path-color-2 {
        stroke: @red;
      }

      &.path-color-3 {
        stroke: @blue;
      }

      &.path-color-4 {
        stroke: @green;
      }

      &.path-color-5 {
        stroke: @orchid;
      }

      &.path-color-6 {
        stroke: @pink;
      }

      &.path-color-7 {
        stroke: @label-muted-color;
      }

      &.path-color-8 {
        stroke: @cyan;
      }
    }

    .line-unavailable {
      stroke-width: 1px;
    }

    .barchart {

      .bar {
        stroke-width: 0;

        line {
          fill-opacity: 1;
          stroke-width: 2px;

          &.hidden,
          &.invisible {
            stroke-width: 0;
          }
        }
      }

      .axis {

        &.x path {
          display: none;
        }

        &.y {

          &.inverse path {
            stroke: fade(@neutral, 10%);
          }

          path {
            stroke: @neutral;
          }
        }

        line {
          display: none;
        }
      }

      .grid-graph {

        .grid.y {

          .tick:first-child line {
            stroke-dasharray: 0, 0;
          }
        }

        &.inverse line {
          stroke: @neutral;
        }

        line {
          stroke: fade(@neutral, 15%);
          stroke-dasharray: 4, 4;
        }
      }
    }

    .timeseries-chart {
      left: 0;
      position: absolute;
      top: 0;

      .moving-elements {
        left: 0;
        position: absolute;
        top: 0;
        transform: translateZ(0);
      }
    }

    .timeseries-chart,
    .barchart {

      .axis {

        &.x,
        &.y {
          font-size: 0.8rem;

          .domain {
            display: none;
          }
        }
      }
    }

    .unit-bordered-horizontal {
      border-right: 1px solid @chart-axis-color;

      &:last-child {
        border-right: none;
      }

      .panel-inverse & {
        border-color: @chart-axis-color-inverse;
      }
    }

    .unit {
      fill: @neutral;
      line-height: 1;

      &.unit-primary {
        font-size: 3rem;
        font-weight: 300;
      }
    }

    .unit-label {
      fill: @purple;

      &.path-color-0 {
        color: @purple;
      }

      &.path-color-1 {
        color: @yellow;
      }

      &.path-color-2 {
        color: @red;
      }

      &.path-color-3 {
        color: @blue;
      }

      &.path-color-4 {
        color: @green;
      }

      &.path-color-5 {
        color: @orchid;
      }

      &.path-color-6 {
        color: @pink;
      }

      &.path-color-7 {
        color: @label-muted-color;
      }

      &.path-color-8 {
        color: @cyan;
      }
    }

    .text-danger {
      fill: @red;
    }

    .text-success {
      fill: @green;
    }

    .text-muted {
      fill: @label-muted-color;
    }
  }

  .dygraph-chart-wrapper {

    .dot {
      display: inline-block;
      margin-right: 6px;
    }

    .dygraph-label-rotate-right {
      text-align: center;
      transform: rotate(-90deg);
    }

    .dygraph-label,
    .graph-legend {
      font-size: 1rem;
    }

    .dygraph-axis-label {
      color: color-lighten(@neutral, 40);
      font-size: 0.75rem;

      &.dygraph-axis-label-y {
        padding-right: 5px;

        em {
          color: color-lighten(@neutral, 20);
        }
      }
    }

    .graph-legend {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      & > span {
        margin-left: 10px;

        &.disabled {
          opacity: 0.7;
        }

        &:hover {
          color: color-lighten(@black, 40);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .dygraph-chart {
    position: relative;
    z-index: @z-index-dygraph-chart;

    .dygraph-hover-label {
      background-color: #171a22;
      border: 1px solid #000;
      border-radius: 4px;
      color: #9b9fa8;
      font-size: 0.9rem;
      padding: 6px 8px;
      pointer-events: none;
      position: absolute;
      width: 200px;
      z-index: @z-index-dygraph-hover-label;

      .highlight {
        background-color: transparent;
      }

      span {
        color: #fff;

        b {

          span {

            &:before {
              background-color: currentColor;
              border-radius: 8px;
              content: '';
              display: inline-block;
              height: 8px;
              margin-right: 4px;
              width: 8px;
            }
          }
        }
      }
    }
  }

  .chart-dialchart {
    position: absolute;

    .description {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      .icon {
        display: inline-block;
      }
    }
  }

  /*
   * Specific to Nodes Grid View
   */
  .nodes-grid-dials {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-left: -(ceil(@base-spacing-unit * 1/3));
    margin-right: -(ceil(@base-spacing-unit * 1/3));

    .nodes-grid-dials-item {
      height: ceil(@base-spacing-unit * (3 + 1/3));
      margin-bottom: ceil(@base-spacing-unit * 1/3);
      margin-right: ceil(@base-spacing-unit * 1/3);
      width: ceil(@base-spacing-unit * (3 + 1/3));

      .chart-dialchart {

        // Remove stroke when dials are in small containers
        .arc path {
          stroke: @panel-background-color;
          stroke-width: 1px;
        }

        .arc {

          &.path-color-0 {
            fill: @resource-cpu-color;
            stroke: @resource-cpu-color;
          }

          &.path-color-1 {
            fill: @yellow;
            stroke: @yellow;
          }

          &.path-color-2 {
            fill: @red;
            stroke: @red;
          }

          &.path-color-3 {
            fill: @resource-disk-color;
            stroke: @resource-disk-color;
          }

          &.path-color-4 {
            fill: @green;
            stroke: @green;
          }

          &.path-color-5 {
            fill: @orange;
            stroke: @orange;
          }

          &.path-color-6 {
            fill: @resource-mem-color;
            stroke: @resource-mem-color;
          }

          &.path-color-7 {
            fill: @cyan;
            stroke: @cyan;
          }

          &.path-color-8 {
            fill: @unique-color-1;
            stroke: @unique-color-1;
          }

          &.path-color-9 {
            fill: @unique-color-2;
            stroke: @unique-color-2;
          }

          &.path-color-10 {
            fill: @unique-color-3;
            stroke: @unique-color-3;
          }

          &.path-color-11 {
            fill: @unique-color-4;
            stroke: @unique-color-4;
          }

          &.path-color-12 {
            fill: @unique-color-5;
            stroke: @unique-color-5;
          }

          &.path-color-13 {
            fill: @unique-color-6;
            stroke: @unique-color-6;
          }

          &.path-color-14 {
            fill: @unique-color-7;
            stroke: @unique-color-7;
          }

          &.path-color-15 {
            fill: @unique-color-8;
            stroke: @unique-color-8;
          }

          &.path-color-16 {
            fill: @unique-color-9;
            stroke: @unique-color-9;
          }

          &.path-color-17 {
            fill: @unique-color-10;
            stroke: @unique-color-10;
          }

          &.path-color-18 {
            fill: @unique-color-11;
            stroke: @unique-color-11;
          }

          &.path-color-19 {
            fill: @unique-color-12;
            stroke: @unique-color-12;
          }

          &.path-color-20 {
            fill: @unique-color-13;
            stroke: @unique-color-13;
          }

          &.path-color-21 {
            fill: @unique-color-14;
            stroke: @unique-color-14;
          }

          &.path-color-22 {
            fill: @unique-color-15;
            stroke: @unique-color-15;
          }

          &.path-color-23 {
            fill: @unique-color-16;
            stroke: @unique-color-16;
          }

          &.path-color-24 {
            fill: @unique-color-17;
            stroke: @unique-color-17;
          }

          &.path-color-25 {
            fill: @unique-color-18;
            stroke: @unique-color-18;
          }

          &.path-color-26 {
            fill: @unique-color-19;
            stroke: @unique-color-19;
          }

          &.path-color-27 {
            fill: @unique-color-20;
            stroke: @unique-color-20;
          }

          &.path-color-28 {
            fill: @unique-color-21;
            stroke: @unique-color-21;
          }

          &.path-color-29 {
            fill: @unique-color-22;
            stroke: @unique-color-22;
          }

          &.path-color-30 {
            fill: @unique-color-23;
            stroke: @unique-color-23;
          }

          &.path-color-31 {
            fill: @unique-color-24;
            stroke: @unique-color-24;
          }

          &.path-color-32 {
            fill: color-lighten(@neutral, 40);
            stroke: color-lighten(@neutral, 40);
          }

          &.path-color-unused {
            fill: color-lighten(@neutral, 80);
            stroke: color-lighten(@neutral, 80);
          }
        }

        .unit {
          color: @neutral;
          font-size: 16px;
        }

        .unit-label {
          font-size: 11px;
        }

        .error {
          position: relative;
          top: -2px;
        }
      }
    }

    .nodes-grid-dials-spacer {
      height: 0;
      margin-right: ceil(@base-spacing-unit * 1/3);
      width: ceil(@base-spacing-unit * (3 + 1/3));
    }
  }

  .chart-cursor-position-marker {
    stroke: fade(@neutral, 15%);
  }
}

& when (@charts-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .chart {

      .unit-bordered-horizontal-small {
        border-right: 1px solid @chart-axis-color;
        margin-top: @base-spacing-unit-screen-small * 1/4;

        &:last-child {
          border-right: none;
        }

        .panel-inverse & {
          border-color: @chart-axis-color-inverse;
        }
      }
    }

    .nodes-grid-dials {
      margin-left: -(ceil(@base-spacing-unit-screen-small * 1/3));
      margin-right: -(ceil(@base-spacing-unit-screen-small * 1/3));

      .nodes-grid-dials-item {
        height: ceil(@base-spacing-unit-screen-small * (3 + 1/3));
        margin-bottom: ceil(@base-spacing-unit-screen-small * 1/3);
        margin-right: ceil(@base-spacing-unit-screen-small * 1/3);
        width: ceil(@base-spacing-unit-screen-small * (3 + 1/3));
      }

      .nodes-grid-dials-spacer {
        margin-right: ceil(@base-spacing-unit-screen-small * 1/3);
        width: ceil(@base-spacing-unit-screen-small * (3 + 1/3));
      }
    }
  }
}

& when (@charts-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .chart {

      .unit-bordered-horizontal-small {
        margin-top: @base-spacing-unit-screen-medium * 1/4;
      }
    }

    .nodes-grid-dials {
      margin-left: -(ceil(@base-spacing-unit-screen-medium * 1/3));
      margin-right: -(ceil(@base-spacing-unit-screen-medium * 1/3));

      .nodes-grid-dials-item {
        height: ceil(@base-spacing-unit-screen-medium * (3 + 1/3));
        margin-bottom: ceil(@base-spacing-unit-screen-medium * 1/3);
        margin-right: ceil(@base-spacing-unit-screen-medium * 1/3);
        width: ceil(@base-spacing-unit-screen-medium * (3 + 1/3));
      }

      .nodes-grid-dials-spacer {
        margin-right: ceil(@base-spacing-unit-screen-medium * 1/3);
        width: ceil(@base-spacing-unit-screen-medium * (3 + 1/3));
      }
    }
  }
}

& when (@charts-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .chart {

      .unit-bordered-horizontal-small {
        margin-top: @base-spacing-unit-screen-large * 1/4;
      }
    }

    .nodes-grid-dials {
      margin-left: -(ceil(@base-spacing-unit-screen-large * 1/3));
      margin-right: -(ceil(@base-spacing-unit-screen-large * 1/3));

      .nodes-grid-dials-item {
        height: ceil(@base-spacing-unit-screen-large * (3 + 1/3));
        margin-bottom: ceil(@base-spacing-unit-screen-large * 1/3);
        margin-right: ceil(@base-spacing-unit-screen-large * 1/3);
        width: ceil(@base-spacing-unit-screen-large * (3 + 1/3));
      }

      .nodes-grid-dials-spacer {
        margin-right: ceil(@base-spacing-unit-screen-large * 1/3);
        width: ceil(@base-spacing-unit-screen-large * (3 + 1/3));
      }
    }
  }
}

& when (@charts-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .chart {

      .unit-bordered-horizontal-small {
        margin-top: @base-spacing-unit-screen-jumbo * 1/4;
      }
    }

    .nodes-grid-dials {
      margin-left: -(ceil(@base-spacing-unit-screen-jumbo * 1/3));
      margin-right: -(ceil(@base-spacing-unit-screen-jumbo * 1/3));

      .nodes-grid-dials-item {
        height: ceil(@base-spacing-unit-screen-jumbo * (3 + 1/3));
        margin-bottom: ceil(@base-spacing-unit-screen-jumbo * 1/3);
        margin-right: ceil(@base-spacing-unit-screen-jumbo * 1/3);
        width: ceil(@base-spacing-unit-screen-jumbo * (3 + 1/3));
      }

      .nodes-grid-dials-spacer {
        margin-right: ceil(@base-spacing-unit-screen-jumbo * 1/3);
        width: ceil(@base-spacing-unit-screen-jumbo * (3 + 1/3));
      }
    }
  }
}
