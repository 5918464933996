.staged {
  animation: candy-stripe 1s linear infinite;
  background-image:
    linear-gradient(-45deg,
    color-lighten(@neutral, 90) 0,
    color-lighten(@neutral, 90) 25%,
    color-lighten(@neutral, 80) 25%,
    color-lighten(@neutral, 80) 50%,
    color-lighten(@neutral, 90) 50%,
    color-lighten(@neutral, 90) 75%,
    color-lighten(@neutral, 80) 75%,
    color-lighten(@neutral, 80) 100%);
  background-size: 16px 16px;
}

& when (@status-bar-enabled) {

  @keyframes candy-stripe {

    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 16px 0;
    }
  }

  .status-bar-with-label-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > .label {
      box-sizing: content-box;
      margin-bottom: 0;
      padding-left: @base-spacing-unit * 0.5;
      width: 3rem;
    }
  }

  .status-bar {
    backface-visibility: hidden;
    background-color: color-lighten(@neutral, 90);
    border-radius: 1000px;
    color: color-lighten(@neutral, 90);
    display: inline-flex;
    height: @base-spacing-unit * 1/6;
    line-height: @base-spacing-unit * 1/6;
    overflow: hidden;
    text-align: left;
    width: @base-spacing-unit * 2;

    &.staged {
      .staged;
    }

    .bar {
      background-color: currentColor;
      box-shadow: 1px 0 0 0 @white;
      display: inline-block;
      height: 100%;
      transition: width 600ms;
      width: 100%;

      &.color-0 {
        background-color: @purple;
      }

      &.color-1 {
        background-color: @yellow;
      }

      &.color-2 {
        background-color: @red;
      }

      &.color-3 {
        background-color: @blue;
      }

      &.staged {
        .staged;
      }

      &.primary {
        background-color: @purple;
      }

      &.color-4,
      &.success {
        background-color: @green;
      }

      &.color-5,
      &.danger {
        background-color: @orchid;
      }

      &.color-6,
      &.warning {
        background-color: @pink;
      }

      &.color-7 {
        background-color: @label-muted-color;
      }

      &.color-8 {
        background-color: @cyan;
      }

      &.disabled {
        background-color: @disabled-color;
      }

      &.over-capacity {
        animation: candy-stripe 1s linear infinite;
        background-image:
          linear-gradient(-45deg,
          color-lighten(@green, -20) 0,
          color-lighten(@green, -20) 25%,
          @green 25%,
          @green 50%,
          color-lighten(@green, -20) 50%,
          color-lighten(@green, -20) 75%,
          @green 75%,
          @green 100%);
        background-size: 16px 16px;
      }
    }

    &--large {
      height: @base-spacing-unit * 1/4;
      line-height: @base-spacing-unit * 1/4;
    }
  }
}

& when (@status-bar-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .status-bar {

      &--large {
        width: @base-spacing-unit * 4;
      }
    }
  }
}
