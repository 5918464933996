& when (@configuration-map-enabled) {

  .configuration-map {
    margin-bottom: @configuration-map-margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }

    &-section {
      margin-bottom: @configuration-map-section-margin-bottom;

      &:first-child {

        & > .configuration-map {

          &-heading {

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    &-row {
      display: flex;

      .table-display-on-row-hover {
        visibility: hidden;
      }

      &:hover {

        .table-display-on-row-hover {
          visibility: visible;
        }
      }

      & + .configuration-map {

        &-row {
          border-top: 1px solid @configuration-map-border-color;
        }
      }
    }

    &-action,
    &-label,
    &-value {
      overflow-wrap: break-word;
      padding: @configuration-map-item-padding-vertical 0;
      vertical-align: middle;
      word-wrap: break-word;
    }

    &-label,
    &-value {
      overflow: hidden;
    }

    // We need specificity here to override table styles
    &-action,
    td&-action {
      text-align: right;
      width: @base-spacing-unit * 3;

      .button {
        height: auto;
      }
    }

    &,
    &-table {

      &-label {

        &,
        & > * {
          overflow-wrap: break-word;
          word-wrap: break-word;
        }

        code,
        pre {
          white-space: pre-wrap;
          word-break: break-all;
        }
      }
    }

    &-label {
      color: @neutral;
      flex: 0 0 @configuration-map-label-width;
      font-weight: 700;
      padding-right: @configuration-map-label-padding-right;
    }

    &-label-no-text-transform {
      text-transform: none;
    }

    &-value {
      flex: 1 1 auto;

      &-stacked {
        padding: 0;

        .configuration-map {

          &-value {
            border-bottom: 1px dotted @configuration-map-border-color;
            display: block;

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    &-table {

      &-label {
        width: @configuration-map-label-width;
      }

      &-value {
        // This is a hack to prevent the "label" table cell
        // from collapsing because of the `min-width: 0px`
        // rule on table cells we require for maintaining
        // the desired column width behavior in the tasks table
        width: 0;
      }
    }
  }
}

& when (@configuration-map-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .configuration-map {
      margin-bottom: @configuration-map-margin-bottom-screen-small;

      &-section {
        margin-bottom: @configuration-map-section-margin-bottom-screen-small;
      }

      &-action,
      &-label,
      &-value {
        padding: @configuration-map-item-padding-vertical-screen-small 0;
      }

      &-action {
        width: @base-spacing-unit-screen-small * 3;
      }

      &-label {
        flex-basis: @configuration-map-label-width-screen-small;
        padding-right: @configuration-map-label-padding-right-screen-small;
      }

      &-table {

        &-label {
          width: @configuration-map-label-width-screen-small;
        }
      }
    }
  }
}

& when (@configuration-map-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .configuration-map {
      margin-bottom: @configuration-map-margin-bottom-screen-medium;

      &-section {
        margin-bottom: @configuration-map-section-margin-bottom-screen-medium;
      }

      &-action,
      &-label,
      &-value {
        padding: @configuration-map-item-padding-vertical-screen-medium 0;
      }

      &-action {
        width: @base-spacing-unit-screen-medium * 3;
      }

      &-label {
        flex-basis: @configuration-map-label-width-screen-medium;
        padding-right: @configuration-map-label-padding-right-screen-medium;
      }

      &-table {

        &-label {
          width: @configuration-map-label-width-screen-medium;
        }
      }
    }
  }
}

& when (@configuration-map-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .configuration-map {
      margin-bottom: @configuration-map-margin-bottom-screen-large;

      &-section {
        margin-bottom: @configuration-map-section-margin-bottom-screen-large;
      }

      &-action,
      &-label,
      &-value {
        padding: @configuration-map-item-padding-vertical-screen-large 0;
      }

      &-action {
        width: @base-spacing-unit-screen-large * 3;
      }

      &-label {
        flex-basis: @configuration-map-label-width-screen-large;
        padding-right: @configuration-map-label-padding-right-screen-large;
      }

      &-table {

        &-label {
          width: @configuration-map-label-width-screen-large;
        }
      }
    }
  }
}

& when (@configuration-map-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .configuration-map {
      margin-bottom: @configuration-map-margin-bottom-screen-jumbo;

      &-section {
        margin-bottom: @configuration-map-section-margin-bottom-screen-jumbo;
      }

      &-action,
      &-label,
      &-value {
        padding: @configuration-map-item-padding-vertical-screen-jumbo 0;
      }

      &-action {
        width: @base-spacing-unit-screen-jumbo * 3;
      }

      &-label {
        flex-basis: @configuration-map-label-width-screen-jumbo;
        padding-right: @configuration-map-label-padding-right-screen-jumbo;
      }

      &-table {

        &-label {
          width: @configuration-map-label-width-screen-jumbo;
        }
      }
    }
  }
}
