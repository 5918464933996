.endpoint-download,
.endpoint-download:hover {
  text-decoration: none;
}

.endpoint-download-icon {
  margin-right: 14px;
}

.endpoint-sdk-deploying,
.plan-sdk-deploying {
  text-align: center;
}
