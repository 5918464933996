& when (@badge-enabled) {

  .badge-container {
    align-items: baseline;
    display: flex;
    flex-grow: 1;

    .badge-container-text {
      flex: 1 1 auto;
      margin-right: 0.25 * @base-spacing-unit;
    }

    .badge {
      flex: 0 0 auto;
    }
  }

  .button {

    .badge {
      background: @button-badge-background-color;
      border-color: @button-badge-background-color;
    }

    &.active,
    &:hover {

      .badge {
        background: @button-badge-hover-background-color;
        border-color: @button-badge-hover-background-color;
      }
    }

    &:active {

      .badge {
        background: @button-badge-active-background-color;
        border-color: @button-badge-active-background-color;
      }
    }
  }

  .button-stroke,
  .dropdown-menu li {

    .badge {
      background: @badge-background-color;
      border-color: @badge-background-color;
    }

    &.active,
    &:hover {

      .badge {
        background: @button-stroke-badge-hover-background-color;
        border-color: @button-stroke-badge-hover-background-color;
      }
    }

    &:active {

      .badge {
        background: @button-stroke-badge-active-background-color;
        border-color: @button-stroke-badge-active-background-color;
      }
    }
  }

  .badge {

    &--primary {
      background-color: @purple;
      border-color: @purple;
      color: @white;
    }
  }
}

& when (@badge-enabled) and (@layout-screen-small-enabled) {

  @media (min-width: @layout-screen-small-min-width) {

    .badge-container {

      .badge {
        margin-left: 0.25 * @base-spacing-unit-screen-small;
      }
    }
  }
}

& when (@badge-enabled) and (@layout-screen-medium-enabled) {

  @media (min-width: @layout-screen-medium-min-width) {

    .badge-container {

      .badge {
        margin-left: 0.25 * @base-spacing-unit-screen-medium;
      }
    }
  }
}

& when (@badge-enabled) and (@layout-screen-large-enabled) {

  @media (min-width: @layout-screen-large-min-width) {

    .badge-container {

      .badge {
        margin-left: 0.25 * @base-spacing-unit-screen-large;
      }
    }
  }
}

& when (@badge-enabled) and (@layout-screen-jumbo-enabled) {

  @media (min-width: @layout-screen-jumbo-min-width) {

    .badge-container {

      .badge {
        margin-left: 0.25 * @base-spacing-unit-screen-jumbo;
      }
    }
  }
}
